export default [
  { header: "Starterkit", icon: "mdi mdi-dots-horizontal" },
  {
    id: "1",
    icon: "key",
    title: "Login",
    i18n: "Login",
    to: "/auth/login",
  },
  {
    id: "1",
    icon: "key",
    title: "Register",
    i18n: "Register",
    to: "/auth/register",
  }
];
