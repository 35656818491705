<template>
  <b-navbar-brand to="/">
       <!-- Logo icon -->
        <b class="logo-icon">
            <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
            <!-- Dark Logo icon -->
            <b-img src="../../../assets/images/owrent-wht.png" fluid style="max-width:150px" alt="homepage" />
            
        </b>
        <!--End Logo icon -->
        <!-- Logo text -->
        <!--<span class="logo-text">
            <img src="../../../assets/images/logo-light-text.png" alt="homepage" >
            
        </span>-->
  </b-navbar-brand>
</template>

<script>
export default {
    name: 'LogoLight',
}
</script>
