<template>
  <b-navbar-brand to="/">
       <!-- Logo icon -->
        <b class="logo-icon">
            <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
            <!-- Light Logo icon -->
            <b-img src="../../../assets/images/owrent.png" style="max-width:150px" alt="homepage" />
            
        </b>
        <!--End Logo icon -->
        <!-- Logo text -->
        <!--<span class="logo-text">
            <img src="../../../assets/images/logo-text.png"  alt="homepage">
        </span>-->
  </b-navbar-brand>
</template>

<script>
export default {
    name: 'LogoDark',
}
</script>
